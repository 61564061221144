.mr {
    margin-right: 1rem !important;
}

.mt {
    margin-top: 1rem !important;
}

.mb {
    margin-bottom: 1rem !important;
}

.ml {
    margin-left: 1rem !important;
}

.pr {
    padding-right: 1rem !important;
}

.pt {
    padding-top: 1rem !important;
}

.pb {
    padding-bottom: 1rem !important;
}

.pl {
    padding-left: 1rem !important;
}

/* for half spacing */ 

.mr-h {
    margin-right: 0.5rem !important;
}

.mt-h {
    margin-top: 0.5rem !important;
}

.mb-h {
    margin-bottom: 0.5rem !important;
}

.ml-h {
    margin-left: 0.5rem !important;
}

.pr-h {
    padding-right: 0.5rem !important;
}

.pt-h {
    padding-top: 0.5rem !important;
}

.pb-h {
    padding-bottom: 0.5rem !important;
}

.pl-h {
    padding-left: 0.5rem !important;
}

/* for medium spacing */
.mr-m {
    margin-right: 1.5rem !important;
}

.mt-m {
    margin-top: 1.5rem !important;
}

.mb-m {
    margin-bottom: 1.5rem !important;
}

.ml-m {
    margin-left: 1.5rem !important;
}

.pr-m {
    padding-right: 1.5rem !important;
}

.pt-m {
    padding-top: 1.5rem !important;
}

.pb-m {
    padding-bottom: 1.5rem !important;
}

.pl-m {
    padding-left: 1.5rem !important;
}


/* for double spacing */

.mr-d {
    margin-right: 2rem !important;
}

.mt-d {
    margin-top: 2rem !important;
}

.mb-d {
    margin-bottom: 2rem !important;
}

.ml-d {
    margin-left: 2rem !important;
}

.pr-d {
    padding-right: 2rem !important;
}

.pt-d {
    padding-top: 2rem !important;
}

.pb-d {
    padding-bottom: 2rem !important;
}

.pl-d {
    padding-left: 2rem !important;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-center {
    text-align: center;
}

.d-flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.jcc {
    justify-content: center;
}

.jcsb {
    justify-content: space-between;
}

.jcfe {
    justify-content: flex-end;
}

.aic {
    align-items: center;
}

.w-100 {
    width: 100%;
}

.min-100vh {
    min-height: 100vh;
}

.h-100 {
    height: 100%;
}
.h-50{
    height:50%;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
}

.face-right {
    transform: rotate(-90deg);
  }
  
  .face-left {
    transform: rotate(90deg);
  }
  
  .face-up {
    transform: rotate(-180deg);
  }
  
  .face-down {
    transform: rotate(0);
  }

.container {
    max-width: 90rem;
    width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
}

.container-fluid {
    width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
}

.capitalize {
    text-transform: capitalize;
}
